.loader {
    .adm-image {
      background-color: #fff;
      border-radius: 50%;
      height: 50px;
      left: 50%;
      height: 60px;
      max-width: 300px;
      overflow: hidden;
      padding: 10px;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 60px;
    }
  }
  
@primary-color: #1D1D1D;@btn-primary-color: #fff;@btn-primary-bg: @primary-color;